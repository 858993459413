import React from "react";

import { useForgotPassword, useTranslate } from "@refinedev/core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  theme,
} from "antd";

import "./styles.css";
import useScreenSize from "utils/useScreenSize";
import { UseForgotPasswordReturnType } from "@refinedev/core/dist/hooks/auth/useForgotPassword";
import LanguageSwitcher from "components/LanguageSwitcher";

const { Text, Title } = Typography;

export interface IForgotPassForm {
  email: string;
}

export const ForgotPassword: React.FC = () => {
  const t = useTranslate();
  const [forgotPassForm] = Form.useForm<IForgotPassForm>();
  const { token } = theme.useToken();
  const { width } = useScreenSize();

  const {
    mutate: forgotPassword,
    isLoading,
  }: UseForgotPasswordReturnType<IForgotPassForm> =
    useForgotPassword<IForgotPassForm>();

  const CardTitle = (
    <Title level={3} className="title" style={{ marginBottom: 0 }}>
      {t("Reset Your Password")}
    </Title>
  );

  return (
    <Layout className="layout" style={{ background: token.colorPrimary }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<IForgotPassForm>
                layout="vertical"
                form={forgotPassForm}
                onFinish={(values: IForgotPassForm) => forgotPassword(values)}
                requiredMark={false}
              >
                <div style={{ marginBottom: 20 }}>
                  <Text>
                    {t(
                      "Type in your email and we'll send you a link to reset your password"
                    )}
                  </Text>
                </div>
                <Form.Item
                  label={t("Email")}
                  name="email"
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    type="email"
                    placeholder="you@example.com"
                    size="large"
                  />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  style={{ marginTop: 22 }}
                  loading={isLoading}
                >
                  {t("Send Reset Email")}
                </Button>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                  }}
                >
                  <Text>
                    {t("Have an account?")}{" "}
                    <a href="/login?tab=pass">{t("Sign In Now")}</a>
                  </Text>
                </div>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          left: width / 2 - 100,
          width: 200,
        }}
      >
        <LanguageSwitcher />
      </div>
    </Layout>
  );
};
