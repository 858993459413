import { useEffect } from "react";
import { Sider as RefineSider } from "@refinedev/antd";
import { Link } from "react-router-dom";
import { Button, Dropdown, Menu, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
  useGetIdentity,
  useLogout,
  useMenu,
  useTranslate,
} from "@refinedev/core";
import { MenuTitle } from "components/menu-title";
import "./styles.css";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import LanguageSwitcher from "components/LanguageSwitcher";
import { useApiToken, useConnectedProviders } from "utils/state-utils";

enum routes {
  transfer = "/transfer",
  aggregate = "/aggregate",
}

interface IProfile {
  id?: string;
  email?: string;
  name?: string;
}

export const Sider = () => {
  const t = useTranslate();
  const { data: userIdentity } = useGetIdentity<IProfile>();
  const [_t, setApiToken] = useApiToken();
  const [_p, setProviders] = useConnectedProviders();
  const { mutate: logout, isSuccess: loggedOut } = useLogout();

  const { selectedKey } = useMenu();

  const isActiveRouteStyles = (
    route: string
  ): React.CSSProperties | undefined =>
    selectedKey.includes(route)
      ? { fontWeight: "bold", border: "1px solid" }
      : undefined;

  useEffect(() => {
    if (loggedOut) {
      setApiToken(null);
      setProviders([]);
    }
  }, [loggedOut]);

  return (
    <RefineSider
      Title={({ collapsed }) => <MenuTitle collapsed={collapsed} />}
      render={({ items, collapsed }) => (
        <>
          <Menu.Item
            key={routes.aggregate}
            icon={<MenuOutlined />}
            style={isActiveRouteStyles(routes.aggregate)}
          >
            <Link to={routes.aggregate}>{t("sidebar.Aggregate")}</Link>
          </Menu.Item>
          {/* <Menu.Item
            key={routes.transfer}
            icon={<MenuOutlined />}
            style={isActiveRouteStyles(routes.transfer)}
          >
            <Link to={routes.transfer}>{t("sidebar.Transfer")}</Link>
          </Menu.Item> */}
          <div
            style={{
              position: "absolute",
              bottom: 50,
              left: 0,
              right: 0,
            }}
          >
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    icon: <LogoutOutlined />,
                    label: t("sidebar.Logout"),
                    onClick: () => logout(),
                  },
                ],
              }}
            >
              <Button type="primary" block>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <UserOutlined />
                  {!collapsed && (
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        marginLeft: 10,
                        fontWeight: 600,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        flex: 1,
                      }}
                    >
                      {userIdentity?.email}
                    </Typography>
                  )}
                </div>
              </Button>
            </Dropdown>
            <div style={{ height: 12 }} />
            <LanguageSwitcher hideLabel={collapsed} />
          </div>
        </>
      )}
    />
  );
};
