import { useGetLocale, useSetLocale } from "@refinedev/core";
import { Avatar, Button, Dropdown } from "antd";
import { useTranslation } from "react-i18next";

export default function LanguageSwitcher({
  hideLabel,
}: {
  hideLabel?: boolean;
}) {
  const { i18n } = useTranslation();
  const changeLanguage = useSetLocale();
  const currentLocale = useGetLocale()();

  return (
    <Dropdown
      menu={{
        items: [...(i18n.languages || [])]
          .sort()
          .map((lang: string, index) => ({
            key: index,
            icon: <Avatar size={16} src={`/flags/${lang}.svg`} />,
            label: lang === "en" ? "English" : "Svenska",
            onClick: () => changeLanguage(lang),
          })),
      }}
    >
      <Button type="primary" block>
        <div
          style={{
            display: "flex",
            gap: 8,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar size={16} src={`/flags/${currentLocale}.svg`} />
          {!hideLabel && (currentLocale === "en" ? "English" : "Svenska")}
        </div>
      </Button>
    </Dropdown>
  );
}
