import React from "react";

import { useTranslate, useUpdatePassword } from "@refinedev/core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  theme,
} from "antd";

import "./styles.css";
import useScreenSize from "utils/useScreenSize";
import { UseUpdatePasswordReturnType } from "@refinedev/core/dist/hooks/auth/useUpdatePassword";
import LanguageSwitcher from "components/LanguageSwitcher";
import { useLocation } from "react-router-dom";

const { Text, Title } = Typography;

export interface IUpdatePassForm {
  password: string;
}

export const UpdatePassword: React.FC = () => {
  const t = useTranslate();
  const [signUpForm] = Form.useForm<IUpdatePassForm>();
  const { token } = theme.useToken();
  const { width } = useScreenSize();
  const invitedUser = useLocation().pathname.includes("invite");

  const {
    mutate: update,
    isLoading,
  }: UseUpdatePasswordReturnType<IUpdatePassForm> =
    useUpdatePassword<IUpdatePassForm>();

  const CardTitle = (
    <Title level={3} className="title">
      {invitedUser ? t("Welcome to FinSquid!") : t("Reset Your Password")}
    </Title>
  );

  return (
    <Layout className="layout" style={{ background: token.colorPrimary }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<IUpdatePassForm>
                layout="vertical"
                form={signUpForm}
                onFinish={(values: IUpdatePassForm) => update(values)}
                requiredMark={false}
              >
                <div style={{ marginBottom: 20 }}>
                  <Text>
                    {invitedUser
                      ? t(
                          "Type in your secure password and press save to get started"
                        )
                      : t(
                          "Type in a new secure password and press save to update your password"
                        )}
                  </Text>
                </div>
                <Form.Item
                  label={t("Password")}
                  name="password"
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  loading={isLoading}
                >
                  {invitedUser ? t("Save Password") : t("Save New Password")}
                </Button>
                {!invitedUser && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 15,
                    }}
                  >
                    <Text>
                      {t("Have an account?")}{" "}
                      <a href="/login?tab=pass">{t("Sign In Now")}</a>
                    </Text>
                  </div>
                )}
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          left: width / 2 - 100,
          width: 200,
        }}
      >
        <LanguageSwitcher />
      </div>
    </Layout>
  );
};
