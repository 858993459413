import { useGetLocale } from "@refinedev/core";
import { theme } from "antd";
import { AliasToken } from "antd/es/theme/internal";
import Loader from "components/Loader";
import { PostMessageData } from "gateway-api/types";
import { useEffect, useState } from "react";
import { errorNotifier } from "utils/helpers";
import { useApiToken } from "utils/state-utils";
import useScreenSize from "utils/useScreenSize";

export default function TransferAccount() {
  const [apiToken] = useApiToken();
  const currentLocale = useGetLocale()();
  const { token } = theme.useToken();
  const { height } = useScreenSize();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const baseUrl = process.env.REACT_APP_FINSQUID_SDK;
  const apiUrl = process.env.REACT_APP_GATEWAY_URL;
  const redirectLink = `${window.location.origin}/aggregate`;

  const transferTheme: Partial<AliasToken> = {
    colorPrimary: token.colorPrimary,
    colorBgLayout: token.colorBgLayout,
    colorBgContainer: token.colorBgContainer,
  };
  const themeParams = encodeURIComponent(JSON.stringify(transferTheme));
  const authSdkLink = `${baseUrl}/transfer/?api_key=${encodeURIComponent(
    apiToken?.token || "null"
  )}&theme=${themeParams}&lang=${currentLocale}&api_url=${apiUrl}&redirect=${redirectLink}`;

  useEffect(() => {
    const handlePostMessage = (event: any) => {
      if (!baseUrl || event.origin !== new URL(baseUrl).origin) return;

      const { type, error }: PostMessageData = event.data;

      if (type === "error") {
        errorNotifier({
          description: (
            <pre>
              {error?.type + "\n"}
              {error?.message}
            </pre>
          ),
        });
      }
    };
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!iframeLoaded && (
        <div style={{ position: "absolute" }}>
          <Loader background="#fff" />
        </div>
      )}
      <iframe
        style={{
          width: 642,
          height: height + 1,
          border: "none",
        }}
        src={authSdkLink}
        onLoad={() => setIframeLoaded(true)}
      />
    </div>
  );
}
