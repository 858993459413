import React from "react";

import { useRegister, useTranslate } from "@refinedev/core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  theme,
} from "antd";

import "./styles.css";
import useScreenSize from "utils/useScreenSize";
import { UseRegisterReturnType } from "@refinedev/core/dist/hooks/auth/useRegister";
import LanguageSwitcher from "components/LanguageSwitcher";

const { Text, Title } = Typography;

export interface ISignUpForm {
  email: string;
  password: string;
}

export const SignUp: React.FC = () => {
  const t = useTranslate();
  const [signUpForm] = Form.useForm<ISignUpForm>();
  const { token } = theme.useToken();
  const { width } = useScreenSize();

  const { mutate: register, isLoading }: UseRegisterReturnType<ISignUpForm> =
    useRegister<ISignUpForm>();

  const CardTitle = (
    <Title level={3} className="title" style={{ marginBottom: 0 }}>
      {t("Get started")}
    </Title>
  );

  return (
    <Layout className="layout" style={{ background: token.colorPrimary }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ISignUpForm>
                layout="vertical"
                form={signUpForm}
                onFinish={(values: ISignUpForm) => register(values)}
                requiredMark={false}
              >
                <Form.Item
                  label={t("Email")}
                  name="email"
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    type="email"
                    placeholder="you@example.com"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label={t("Password")}
                  name="password"
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  loading={isLoading}
                >
                  {t("Sign Up")}
                </Button>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                  }}
                >
                  <Text>
                    {t("Have an account?")}{" "}
                    <a href="/login?tab=pass">{t("Sign In Now")}</a>
                  </Text>
                </div>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          left: width / 2 - 100,
          width: 200,
        }}
      >
        <LanguageSwitcher />
      </div>
    </Layout>
  );
};
