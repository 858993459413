import {
  ErrorComponent,
  ThemedLayoutV2,
  notificationProvider,
} from "@refinedev/antd";
import { Authenticated, I18nProvider, Refine } from "@refinedev/core";
import routerProvider, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
  useDocumentTitle,
} from "@refinedev/react-router-v6";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import "@refinedev/antd/dist/reset.css";
import { ConfigProvider, notification, theme } from "antd";

import Sider from "components/Sider";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import ScrollToTopOnRouteChange from "utils/scrollToTop";
import TransferAccount from "pages/TransferAccount.tsx";
import "./styles.css";
import "./i18n";
import svSE from "antd/locale/sv_SE";
import enUS from "antd/locale/en_US";
import { useTranslation } from "react-i18next";
import { Translate } from "@refinedev/core/dist/interfaces";
import Aggregate from "pages/Aggregate";
import { dataProvider, liveProvider } from "@refinedev/supabase";
import { supabaseClient } from "utils/supabaseClient";
import authProvider, { getApiToken } from "authProvider";
import ForgotPassword from "pages/ForgotPassword";
import UpdatePassword from "pages/UpdatePassword";
import { useEffect } from "react";
import { useApiToken, useConnectedProviders } from "utils/state-utils";
import { Session } from "@supabase/gotrue-js";
import SignupConfirmation from "pages/SignupConfirmation";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [apiToken, setApiToken] = useApiToken();
  const [_p, setProviders] = useConnectedProviders();

  const i18nProvider: I18nProvider = {
    translate: ((key: string, options?: any) => t(key, options)) as Translate,
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  useDocumentTitle("FinSquid");

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    onFocus();
    const { data: authStateChange } = supabaseClient.auth.onAuthStateChange(
      (event, session) => {
        if (
          event == "TOKEN_REFRESHED" ||
          event == "SIGNED_IN" ||
          (event == "USER_UPDATED" &&
            (!apiToken?.expires || apiToken?.expires < Date.now()) &&
            session)
        )
          setApiTokenToStorage(session);
      }
    );
    return () => {
      authStateChange.subscription.unsubscribe();
      window.removeEventListener("focus", onFocus);
    };
  }, [apiToken]);

  const setApiTokenToStorage = async (session: Session | null) => {
    try {
      const apiTokenRes = await getApiToken(session);
      setApiToken(apiTokenRes);
    } catch (err) {
      console.log("setApiTokenToStorage error: ", err);
      notification.info({
        message: t("Encountering an API token error. Please sign in again."),
        duration: 0,
        btn: <button onClick={() => location.reload()}>Ok</button>,
        closeIcon: false,
      });
      supabaseClient.auth.signOut();
      setApiToken(null);
      setProviders([]);
    }
  };

  const onFocus = async () => {
    if (!apiToken?.expires || apiToken?.expires < Date.now()) {
      const {
        data: { session },
      } = await supabaseClient.auth.getSession();
      session && setApiTokenToStorage(session);
    }
  };

  return (
    <BrowserRouter>
      <ScrollToTopOnRouteChange />
      <ConfigProvider
        locale={i18n.resolvedLanguage === "en" ? enUS : svSE}
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: "#7D00FF",
            colorBgContainer: "#fff",
            colorBgLayout: "#F5F7FE",
          },
          components: {
            Layout: {
              colorBgHeader: "#7D00FF",
              colorBgLayout: "#FFFFFF",
              colorBgTrigger: "#7D00FF",
              colorTextBase: "#000",
              colorText: "#000",
              colorBorder: "#666666",
              colorBgContainer: "#fff",
            },
            Menu: {
              itemSelectedBg: "#7D00FF",
            },
          },
        }}
      >
        <Refine
          i18nProvider={i18nProvider}
          dataProvider={dataProvider(supabaseClient)}
          liveProvider={liveProvider(supabaseClient)}
          routerProvider={routerProvider}
          authProvider={authProvider}
          resources={[
            {
              name: "aggregate",
              list: "/aggregate",
            },
          ]}
          notificationProvider={notificationProvider}
          options={{
            warnWhenUnsavedChanges: true,
            syncWithLocation: true,
          }}
        >
          <Routes>
            <Route index element={<Navigate to="/aggregate" />} />

            <Route
              element={
                <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                  <ThemedLayoutV2 Header={() => null} Sider={() => <Sider />}>
                    <Outlet />
                  </ThemedLayoutV2>
                </Authenticated>
              }
            >
              <Route path="aggregate" element={<Aggregate />} />
              <Route path="transfer" element={<TransferAccount />} />
            </Route>

            <Route
              element={
                <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                  <Outlet />
                </Authenticated>
              }
            >
              <Route path="/update-password/*" element={<UpdatePassword />} />
            </Route>

            <Route
              element={
                <Authenticated fallback={<Outlet />}>
                  <NavigateToResource resource="aggregate" />
                </Authenticated>
              }
            >
              <Route path="/login" element={<SignIn />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/confirmation" element={<SignupConfirmation />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>

            <Route
              element={
                <Authenticated>
                  <ThemedLayoutV2 Header={() => null}>
                    <Outlet />
                  </ThemedLayoutV2>
                </Authenticated>
              }
            >
              <Route path="*" element={<ErrorComponent />} />
            </Route>
          </Routes>
          <UnsavedChangesNotifier />
        </Refine>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
