import React from "react";
import {
  TitleProps,
  useRouterContext,
  useRouterType,
  useLink,
} from "@refinedev/core";
import { Image } from "antd";

export const MenuTitle: React.FC<TitleProps> = ({ collapsed }) => {
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  return (
    <ActiveLink to="/">
      {collapsed ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src="/finsquid_icon_white.svg"
            alt="FinSquid"
            preview={false}
            style={{
              margin: "0.4rem auto",
              padding: "0.8rem",
            }}
          />
        </div>
      ) : (
        <Image
          preview={false}
          src="/finsquid_logo_white.svg"
          alt="FinSquid"
          style={{
            padding: "1rem",
          }}
        />
      )}
    </ActiveLink>
  );
};
