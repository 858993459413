import { AuthBindings } from "@refinedev/core";
import { notification } from "antd";
import { supabaseClient } from "./utils/supabaseClient";
import { t } from "i18next";
import { Session } from "@supabase/gotrue-js";

export const getApiToken: (
  session: Session | null
) => Promise<{ token: string; expires: number }> = async (session) => {
  if (!session) throw "Unauthorized";

  const res = await fetch(`${window.location.origin}/getApiToken`, {
    headers: { Authorization: `Bearer ${session?.access_token}` },
  });

  const resJson = await res.json();
  return resJson;
};

const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    try {
      if (password) {
        const { data, error } = await supabaseClient.auth.signInWithPassword({
          email,
          password,
        });
        if (error) throw error;

        if (data?.user) {
          return {
            success: true,
            redirectTo: "/",
          };
        }
      }

      const { error } = await supabaseClient.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: `${window.location.origin}/aggregate`,
        },
      });

      if (!error) {
        notification.success({
          message: t("Check your email for the login link!"),
          duration: 10,
          placement: "top",
        });
        return {
          success: true,
        };
      }

      throw error;
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }
  },
  register: async ({ email, password }) => {
    try {
      const { data, error } = await supabaseClient.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/aggregate`,
        },
      });

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        notification.success({
          message: t("Check your email to confirm"),
          description: t(
            "You've successfully signed up. Please check your email to confirm your account before signing in"
          ),
          duration: 10,
        });
        return {
          success: true,
          redirectTo: "/login?tab=pass",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: false,
      error: {
        message: t("Register failed"),
        name: t("Invalid email or password"),
      },
    };
  },
  forgotPassword: async ({ email }) => {
    try {
      const { data, error } = await supabaseClient.auth.resetPasswordForEmail(
        email,
        {
          redirectTo: `${window.location.origin}/update-password`,
        }
      );

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        notification.success({
          message: t(
            "If you registered using your email and password, you will receive a password reset email."
          ),
          duration: 10,
        });
        return {
          success: true,
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: false,
      error: {
        message: t("Forgot password failed"),
        name: t("Invalid email"),
      },
    };
  },
  updatePassword: async ({ password }) => {
    try {
      const { data, error } = await supabaseClient.auth.updateUser({
        password,
      });

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        notification.success({
          message: t("Password saved successfully!"),
          duration: 10,
        });
        return {
          success: true,
          redirectTo: "/",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: false,
      error: {
        message: t("Update password failed"),
        name: t("Invalid password"),
      },
    };
  },
  logout: async () => {
    const { error } = await supabaseClient.auth.signOut();

    if (error) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
  check: async () => {
    try {
      const {
        data: { session },
      } = await supabaseClient.auth.getSession();

      if (!session) {
        return {
          authenticated: false,
          error: {
            message: t("Check failed"),
            name: t("Session not found"),
          },
          logout: true,
          redirectTo: "/login",
        };
      }
    } catch (error: any) {
      return {
        authenticated: false,
        error: error || {
          message: t("Check failed"),
          name: t("Not authenticated"),
        },
        logout: true,
        redirectTo: "/login",
      };
    }

    return {
      authenticated: true,
    };
  },
  getIdentity: async () => {
    const { data } = await supabaseClient.auth.getUser();

    if (data?.user) {
      return {
        ...data.user,
        name: data.user.email,
      };
    }

    return null;
  },
};

export default authProvider;
