import { useTranslate } from "@refinedev/core";
import { Button, Card, Col, Layout, Row, Typography, theme } from "antd";
import LanguageSwitcher from "components/LanguageSwitcher";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useScreenSize from "utils/useScreenSize";

const { Title } = Typography;

export const SignupConfirmation = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { width } = useScreenSize();
  const { confirmation_url } = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  useEffect(() => {
    if (!confirmation_url) navigate("/login");
  }, [confirmation_url]);

  const CardTitle = (
    <Title level={3} className="title" style={{ marginTop: 16 }}>
      {t("Welcome back")}
    </Title>
  );

  return (
    <Layout className="layout" style={{ background: token.colorPrimary }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <Card
            title={CardTitle}
            headStyle={{ borderBottom: 0 }}
            style={{ maxWidth: 408, margin: "auto" }}
          >
            <Button
              type="primary"
              size="large"
              block
              onClick={() => (window.location.href = confirmation_url)}
            >
              {t("Authorize")}
            </Button>
          </Card>
        </Col>
      </Row>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          left: width / 2 - 100,
          width: 200,
        }}
      >
        <LanguageSwitcher />
      </div>
    </Layout>
  );
};
