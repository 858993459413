import { useGetLocale } from "@refinedev/core";
import { Grid, theme } from "antd";
import { ComponentToken } from "antd/es/button/style";
import { AliasToken } from "antd/es/theme/internal";
import Loader from "components/Loader";
import { useEffect, useRef, useState } from "react";
import { errorNotifier } from "utils/helpers";
import {
  ProviderT,
  useApiToken,
  useConnectedProviders,
} from "utils/state-utils";
import useScreenSize from "utils/useScreenSize";

export type PostMessageData = {
  type: "providers" | "error";
  data?: any;
  error?: { type?: string; message?: any } | null;
};

export default function Aggregate() {
  const [apiToken] = useApiToken();
  const aggregateRef = useRef<HTMLIFrameElement>(null);
  const currentLocale = useGetLocale()();
  const { token } = theme.useToken();
  const { xs, sm, md } = Grid.useBreakpoint();
  const { width, height } = useScreenSize();
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [providers, setConnectedProviders] = useConnectedProviders();

  const baseUrl = process.env.REACT_APP_FINSQUID_SDK;
  const apiUrl = process.env.REACT_APP_GATEWAY_URL;
  const redirectLink = `${window.location.origin}/aggregate`;

  const aggregateTheme: Partial<AliasToken> | ComponentToken = {
    colorPrimary: token.colorPrimary,
    colorBgLayout: token.colorBgLayout,
    colorBgContainer: token.colorBgContainer,
  };
  const themeParams = encodeURIComponent(JSON.stringify(aggregateTheme));
  const aggregateSdkLink = `${baseUrl}/aggregate/?api_key=${encodeURIComponent(
    apiToken?.token || "null"
  )}&iframe=true&theme=${themeParams}&lang=${currentLocale}&api_url=${apiUrl}&redirect=${redirectLink}`;

  useEffect(() => {
    passProvidersToAggregateSDK(providers);
  }, [isIframeLoaded, providers]);

  useEffect(() => {
    const handlePostMessage = (event: any) => {
      if (!baseUrl || event.origin !== new URL(baseUrl).origin) return;

      const { type, data, error }: PostMessageData = event.data;

      if (type === "providers") {
        setConnectedProviders(data);
        console.log("Aggregate providers message: ", data);
      } else if (type === "error") {
        errorNotifier({
          description: (
            <pre>
              {error?.type + "\n"}
              {error?.message}
            </pre>
          ),
        });
      }
    };
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [providers]);

  const passProvidersToAggregateSDK = (_providers: ProviderT[]) =>
    aggregateRef.current?.contentWindow?.postMessage(
      JSON.stringify({
        type: "providers",
        data: _providers,
      }),
      "*"
    );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!isIframeLoaded && (
        <div style={{ position: "absolute" }}>
          <Loader background="#fff" />
        </div>
      )}
      <iframe
        ref={aggregateRef}
        style={{
          width: (xs || sm) && !md ? width : 1000,
          height: height + 1,
          border: "none",
          background: token.colorBgLayout,
        }}
        src={aggregateSdkLink}
        onLoad={() => setIsIframeLoaded(true)}
      />
    </div>
  );
}
