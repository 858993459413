import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Provider } from "gateway-api/LoginService";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

export interface SetGlobalState<T> {
  (state: T | ((prevState: T) => T)): void;
}

const GLOBAL_STATE_KEY_PREFIX = "globalState";

export function useGlobalState<T = undefined>(
  key: string
): [T, SetGlobalState<T>];
export function useGlobalState<T>(
  key: string,
  initialState: T
): [T, SetGlobalState<T>];
export function useGlobalState<T>(
  key: string,
  initialState?: T
): [T | undefined, SetGlobalState<T>] {
  const queryClient = useQueryClient();

  const stateKey = [GLOBAL_STATE_KEY_PREFIX, key];
  const { data } = useQuery(stateKey, () => initialState, {
    initialData: initialState,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const setData: SetGlobalState<T> = (state) => {
    const newState = state instanceof Function ? state(data as T) : state;
    queryClient.setQueryData(stateKey, newState);
  };

  return [data, setData];
}

export type ProviderConnectT = Provider & {
  sid?: string | null;
};

export type ProviderT = Omit<ProviderConnectT, "sid"> & { sid: string };

export const useConnectedProviders = () => {
  return useLocalStorage<ProviderT[]>("connectedProviders", []);
};

export const useApiToken = () => {
  return useLocalStorage<{ token: string; expires: number } | null | undefined>(
    "apiToken",
    null
  );
};

const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  });

  const updateStoredValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        if (JSON.stringify(valueToStore) !== JSON.stringify(storedValue)) {
          setStoredValue(valueToStore);
          localStorage.setItem(key, JSON.stringify(valueToStore));
          window.dispatchEvent(new Event("storageChanged"));
        }
      } catch (error) {
        console.error(`Error writing localStorage key "${key}":`, error);
      }
    },
    [key, storedValue]
  );

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const newValue = localStorage.getItem(key);
        if (
          newValue !== null &&
          JSON.stringify(JSON.parse(newValue || "")) !==
            JSON.stringify(storedValue)
        ) {
          setStoredValue(JSON.parse(newValue || ""));
        }
      } catch (error) {
        console.error(`Error updating localStorage key "${key}":`, error);
      }
    };

    window.addEventListener("storageChanged", handleStorageChange);

    return () => {
      window.removeEventListener("storageChanged", handleStorageChange);
    };
  }, [key, storedValue]);
  return [storedValue, updateStoredValue];
};

export default useLocalStorage;
